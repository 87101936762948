import React, { useEffect } from "react"

export default function GTMTracker(props) {
  const gtmId = props.gtmId

  useEffect(() => {
    if (window !== undefined && window.document) {
      import("react-gtm-module").then(({ default: tagManager }) => {
        const tagAarray = gtmId.split(",")
        for(var i = 0; i< tagAarray.length; i++){
          tagManager.initialize({"gtmId": tagAarray[i]});
        }
      })
    }
  }, [])

  return (<></>)
}
